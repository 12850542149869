import { createService, setReqInterceptors, requestCache } from "./axios"
import router from "@/router"
import store from "@/store"
import { MessageBox, Message, ButtonGroup } from "element-ui"
import { handleRequestCache, RequestError } from "@/utils/request-deduplication"

import { getRefreshToken } from "@/utils/auth.js"
import { getOauthTokenApi } from "@/api/auth.js"
import { SET_AUTH } from "@/store/mutation.type"

const service = createService()
// token是否过期
let isTokenError = true // 解决判断token过期多次提醒
// console.log(2222,isTokenError);

// 设置请求拦截
setReqInterceptors(service, requestCache)
// 刷新token
function refreshToken (response) {
  // 获取refreshToken
  const refreshToken = getRefreshToken()

  function reLogin() {
    store.dispatch("user/resetToken")
    router.push("/password-login")
    location.reload()
  // Bug#2484==按照评论修改
  // Message.error({
    //   message: "登录过期，请重新登录",
  //   grouping: true,
  //   type: "error"
  // })
  }
  if (!refreshToken) { return reLogin() }
  if (isTokenError) {
    isTokenError = false
    return getOauthTokenApi({
      client_id: "client_prepay_web",
      client_secret: "123456",
      grant_type: "refresh_token",
      refresh_token: refreshToken
    }).then((res) => {
      store.commit(`user/${SET_AUTH}`, res)
      const config = response.config
      const temp = {
        ...config,
        unique: true
      }
      isTokenError = true
      return service({
        ...temp,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${res.access_token}`
        }
      })
    }).catch((error) => {
      console.log("refresh error", error)
    })
  }
}

// 响应拦截器
service.interceptors.response.use(
  response => {
    // 如果请求返回，对应的requestCache.isResponse设置为true
    handleRequestCache(requestCache, response.config)

    // 注意：后端所有可以接受的请求，返回的 HTTP 状态码都是 200，因此要用 code 区分接口是否报错
    const { data, message, code } = response.data
    const customMessage = message
    if (code === "00000") return data
    if (code === "20003") return refreshToken(response) // 登录过期
    if (code === "20008" || code === "12002" || code === "12001") return Promise.reject(response.data) // 登录过期
    // code 不等于 00000，判断接口报错

    // 20003 表示 token 过期
    if (code === "20004") {
      // 清除缓存，跳转登录页
      store.dispatch("user/resetToken")
      // window.localStorage.clear()
      router.push("/password-login")
      location.reload()
      // customMessage = "登录过期，请重新登录"
      // if (isTokenError) {
      //   isTokenError = false
      //   Message.error("登录过期，请重新登录")
      // }
      return
    }

    if (code === "20208") {
      return MessageBox.confirm("抱歉！您所在企业可创建用户的数量已达上限，无法进行创建操作！", "提示", {
        confirmButtonText: "知道了",
        showCancelButton: false,
        type: "warning"
      })
    }

    // 建筑管理 频繁删除建筑后端有限制：10秒请求五次输入密码的接口，接口第六次就会提示：请求过于频繁请稍后再试
    if (code === "21000") {
      return Promise.reject(new Error("请求过于频繁请稍后再试"))
    }

    // 10000 代表内部错误
    if (code === "10000") {
      return Promise.reject(new Error("系统运行异常，请稍后重试"))
    }

    return Promise.reject(new Error(customMessage || "网络请求失败，请稍后重试"))
  },
  error => {
    console.log("error", error)
    // 自定义错误类直接抛出
    if (error instanceof RequestError) {
      throw error
    } else {
      // 如果请求返回，无论错误失败，对应的requestKey.isResponse设置为true
      handleRequestCache(requestCache, error.config)
      let message
      const { code, message: responseMessage, response } = error
      const { status } = response || {}
      switch (status) {
        case 500:
          message = "服务器连接失败"
          break
        case 401:
          message = "登录过期，请重新登录"
          break
        default:
          message = `系统异常，请刷新页面或检查网络连接(错误码:${status})`
      }

      if (code === "ECONNABORTED") {
        if (responseMessage === "Request aborted") message = ""
        else message = "请求超时，请刷新页面后重试"
      }
      return Promise.reject(new Error(message))
    }
  }
)

export default service
