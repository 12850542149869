import { getAccessToken, getCompanyId, getPassword } from "@/utils/auth"
import store from "@/store"
// 白名单
const whiteList = ["/password-login", "/password-forgetting"]

export default function setupRouterGuard (router) {
  router.beforeEach((to, _, next) => {
    const hasToken = getAccessToken()
    const hasCompanyId = getCompanyId()
    const hasPassword = getPassword()

    // fix bug: https://ukia20220713033308660.pingcode.com/pjm/items/63466c44a83a7825ddc359bd
    if (hasToken) {
      //   初始密码并且存在企业ID 才能登录
      if (hasCompanyId && hasPassword) {
        // 登录页面重定向到默认页面
        const isLoginPage = to.name === "PasswordLogin"
        if (isLoginPage) {
          next({ path: localStorage.getItem("defaultActive") })
        } else {
          if (!store.state.user.menuList.length) {
            store.dispatch("user/getMenu").then((res) => {
              const menus = res // JSON.parse(localStorage.getItem("menuRoutes"))
              if (res && !res.length) {
                if (to.path !== "/personal-information") {
                  next("/personal-information")
                } else {
                  next() // If already on the target page, proceed without redirection
                }
              } else {
                if (to.path === "/personal-information") { next() }
                else if (to.path === "/") {
                  if (menus.some(m => m.resourceUrl === to.path)) { next() }
                } else {
                  const curPath = menus.reduce((path, menu) => {
                    if (menu.children && menu.children.some(m => m.resourceUrl === to.path)) {
                      path = to.path
                      next()
                    }
                    return path
                  }, "")
                  if (!curPath) {
                    // 刷新后当前路径失权，有首页跳首页/无首页跳第一条
                    if (menus[0].resourceUrl) { next("/") }
                    else {
                      const path = menus[0].children && menus[0].children[0].resourceUrl
                      localStorage.setItem("defaultActive", path)
                      next(path)
                    }
                  }
                }
                next()
              }
            })
          } else {
            const menus = store.state.user.menuList // JSON.parse(localStorage.getItem("menuRoutes"))
            if (to.path === "/personal-information") { next() }
            else if (to.path === "/") {
              if (menus.some(m => m.resourceUrl === to.path)) { next() }
            } else {
              const curPath = menus.reduce((path, menu) => {
                if (menu.children && menu.children.some(m => m.resourceUrl === to.path)) {
                  path = to.path
                  localStorage.setItem("defaultActive", path)
                  next()
                }
                return path
              }, "")
              if (!curPath) return // 地址栏跳转拦截
            }
            next()
          }
        }
      } else if (to.name === "PersonalInformation" || to.name === "PasswordSetting") {
        //  个人中心页面放行
        next()
      } else {
        store.dispatch("user/resetToken")
        next()
      }
    } else {
      if (whiteList.indexOf(to.path) !== -1) {
        next()
      } else {
        next("/password-login")
      }
    }
  })
}

